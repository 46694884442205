import React, { useEffect, useState } from "react";

import 'bootstrap/dist/js/bootstrap';
import { Container, Nav, Navbar } from 'react-bootstrap';

const NavbarMenu = (props) => {
    const [currentUser, setCurrentUser] = useState(1);
    const { auth } = props;

    useEffect(() => {
        console.log("AUTH NAVBAR", auth.user.perfiles.some(v => v.nombre == "rrhh"));
    }, []);

    return (
        <Navbar expand="lg" bg="light">
            <Container>
                <Navbar.Brand href="#">
                    <img src="/img/ecora_nombre.png" alt="Ecora" width="100" height="34" className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {/* <Nav.Link href={"/mod"} className="nav-item">Mostrar Reportes</Nav.Link> */}
                        {auth.user.perfiles.some(v => v.nombre === "admin" || v.nombre === "gerente") ? (
                            <>
                                <Nav.Link href={"/gerencia"} className="nav-item">Reportes Gerenciales</Nav.Link>
                                <Nav.Link href={"/rrhh"} className="nav-item">Reportes RRHH</Nav.Link>
                                <Nav.Link href={"/compras"} className="nav-item">Reportes Compras</Nav.Link>
                            </>
                        ) : auth.user.perfiles.some(v => v.nombre === "rrhh") ? (
                            <Nav.Link href={"/rrhh"} className="nav-item">Reportes RRHH</Nav.Link>
                        ) : auth.user.perfiles.some(v => v.nombre === "adc") ? (
                            <>
                                <Nav.Link href={"/rrhh"} className="nav-item">Reportes RRHH</Nav.Link>
                                <Nav.Link href={"/compras"} className="nav-item">Reportes Compras</Nav.Link>
                            </>
                        ) : auth.user.perfiles.some(v => v.nombre === "compras") ? (
                            <Nav.Link href={"/compras"} className="nav-item">Reportes Compras</Nav.Link>
                        ) : ""}
                    </Nav>
                    {currentUser >= 1 ? (
                        <Nav className="navbar-nav ml-auto">
                            <Nav.Link href={"/profile"} className="nav-item">{currentUser?.nombre}</Nav.Link>
                            <Nav.Link href={"/"} className="nav-link" onClick={() => auth.logOut()}>Salir</Nav.Link>
                        </Nav>
                    ) : (
                        <Nav className="navbar-nav ml-auto">
                            <Nav.Link href={"/login"} className="nav-item">Ingresar</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavbarMenu;