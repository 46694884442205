import React from "react";

/** BOOTSTRAP */
import { Nav, Tab } from 'react-bootstrap';

/** Componentes */
import { useAuth } from "../components/authprovider.component";
import NavbarMenu from "../components/navbar.component";

const BoardCompras = () => {
    const auth = useAuth();

    return (
        <div>
            <NavbarMenu auth={auth} />
        <div className="container fondo_general">
            <header className="bg-light p-4 mt-3">
                <h3 className="text-center">Sección de Reportes Compras</h3>
            </header>
            <br />
            <Tab.Container>
                <table className="table">
                    <tr>
                        <td><Nav.Link className="text-center" href="/reportecompras/1/ReportSection480ebcde90d2e8999500" eventKey="#link3" aria-describedby="reporte_tarea"><img src="/img/iconos/detalle.png" alt="Reporte de Tareas" width="100" height="100" /></Nav.Link><div id="reporte_tarea" className="form-text text-center">Reporte Vistos Buenos</div></td>
                        <td><Nav.Link className="text-center" href="/reportecompras/2/ReportSectioncdb44c43f0f54b074ae0" aria-describedby="reporte_rendimiento"><img src="/img/iconos/rendimiento.png" alt="Reporte de Rendimiento" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Tiempos por Proyecto</div></td>
                    </tr>
                </table>
            </Tab.Container>
        </div>
        </div>
    );
}

export default BoardCompras;